import { FC } from "react";
import "./Footer.css";

const Footer: FC = () => {
  return (
    <footer>
      Brandmessage.ai is a website owned and operated by Get Clear Consulting
      LTD. It was designed by Certified StoryBrand Guide, Jon Morrison. Get
      Clear Consulting uses the StoryBrand framework to help our clients grow
      their business by improving their marketing. Get Clear Consulting is a
      distinct entity from StoryBrand or Donald Miller Words, LLC. All
      trademarks™ or registered® trademarks of products and companies mentioned
      belong to their respective owners. The use of these trademarks does not
      indicate any affiliation or endorsement.
      <br></br>
      <br></br>
      "Robot Playground" (https://skfb.ly/6QXFq) by Hadrien59 is licensed under
      Creative Commons Attribution
      (http://creativecommons.org/licenses/by/4.0/).
    </footer>
  );
};

export default Footer;
