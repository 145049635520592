import { FC } from "react";
import Jon from "./jon.jpg";
import StoryBrand from "./storybrand.png";
import "./WhoMadeThis.css";

export interface WhoMadeThisProps {}

const WhoMadeThis: FC<WhoMadeThisProps> = () => {
  return (
    <section className="who-made-this container">
      <div className="who-made-this-block img-right">
        <img className="img jon" src={Jon} alt="Jon Morrison" />
        <div className="content">
          <h2 className="who-made-this-block-heading">
            Who Made This Resource?
          </h2>
          <p>
            This tool was created to help small business owners who hate writing
            to learn how to leverage the StoryBrand framework in their
            marketing.
          </p>
          <p>
            It was designed by Certified StoryBrand Guide, Jon Morrison. Jon is
            the Lead Consultant at Get Clear Consulting, a Canadian marketing
            agency.
          </p>
          <p>
            If you’d like some help bringing this work to life, connect with the
            Get Clear team at&nbsp;
            <a href="https://www.getclear.ca" target="_blank" rel="noreferrer">
              www.getclear.ca
            </a>
          </p>
        </div>
      </div>

      <div className="who-made-this-block">
        <img
          className="img storybrand"
          src={StoryBrand}
          alt="StoryBrand Guide"
        />
        <div className="content">
          <h2 className="who-made-this-block-heading">
            Would you like us to make this a website for you?
          </h2>
          <p>
            <b>
              Our prices are competitive and our work ethic is second to none.
            </b>
          </p>
          <p>
            Schedule a call with us and discover why we have over 50, 5-Star
            Google Reviews.
          </p>

          <div className="buttons-container">
            <a
              className="book-btn action-btn"
              href="https://getclear.ca/pages/consultation-call"
              target="_blank"
              rel="noreferrer"
            >
              Schedule A Call
            </a>
            <a
              className="book-btn action-btn secondary-action-btn"
              href="https://getclear.ca/collections/the-get-clear-portfolio"
              target="_blank"
              rel="noreferrer"
            >
              Check Out Our Portfolio
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoMadeThis;
