import { FC, FormEventHandler, useRef, useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import "./Form.css";

const LAST_QUESTION_KEY = 5;

export interface Questions {
  1: string;
  2: string;
  3: string;
  4: string;
  5: string;
}

export type SendQuestions = (questions: string[]) => Promise<void>;

export interface FormProps {
  sendQuestions: SendQuestions;
}

const Form: FC<FormProps> = ({ sendQuestions }) => {
  const [step, setStep] = useState<number>(1);
  const [other, setOther] = useState("");
  const nodeRef = useRef(null);

  const [form, setForm] = useState<Questions>({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
  });

  const onSubmit: FormEventHandler = async (e) => {
    e.preventDefault();

    if (!(form as any)[step]) return;

    if (step === LAST_QUESTION_KEY) {
      const questions = [
        form[1],
        form[2],
        form[3],
        form[4],
        form[LAST_QUESTION_KEY],
      ];
      await sendQuestions(questions);
      return;
    }

    setStep(step + 1);
  };

  return (
    <div className="form">
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={step}
          nodeRef={nodeRef}
          addEndListener={(done: any) => {
            (nodeRef.current as any).addEventListener(
              "transitionend",
              done,
              false
            );
          }}
          classNames="form-fade"
          timeout={300}
        >
          <div ref={nodeRef} className="form-questions">
            <div className="form-questions-header">
              <h2 className="heading">
                {step === 1 && "👋 What is your company name?"}
                {step === 2 && "Who are your best customers?"}
                {step === 3 &&
                  "What problem does your business solve for its customers?"}
                {step === 4 && "What products or services do you offer?"}
                {step === LAST_QUESTION_KEY && "What is the call to action?"}
              </h2>
              <p className="question-description">
                {step === 2 &&
                  "Briefly describe the people you love working with most."}
                {step === 3 &&
                  "Tell us a bit about why people reach out for help."}
                {step === 4 && "What do you do to solve customers' problems?"}
              </p>
            </div>
            <form onSubmit={onSubmit} className="input-form">
              {step < LAST_QUESTION_KEY ? (
                <input
                  id="question-input"
                  autoFocus={step > 1 ? true : false}
                  maxLength={300}
                  type="text"
                  className="input"
                  value={(form as any)[step]}
                  onChange={(e) => setForm({ ...form, [step]: e.target.value })}
                />
              ) : (
                <div className="radio-group">
                  <label>
                    <input
                      type="radio"
                      name="cta"
                      value="Book a Call"
                      checked={form[5] === "Book a Call"}
                      onChange={(e) => {
                        setOther("");
                        setForm({ ...form, [step]: e.target.value });
                      }}
                      required
                    />
                    Book a Call
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="cta"
                      value="Buy Now"
                      checked={form[5] === "Buy Now"}
                      onChange={(e) => {
                        setOther("");
                        setForm({ ...form, [step]: e.target.value });
                      }}
                      required
                    />
                    Buy Now
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="cta"
                      value="Schedule An Appointment"
                      checked={form[5] === "Schedule An Appointment"}
                      onChange={(e) => {
                        setOther("");
                        setForm({ ...form, [step]: e.target.value });
                      }}
                      required
                    />
                    Schedule An Appointment
                  </label>
                  <label className="other-radio-input">
                    <input
                      type="radio"
                      name="cta"
                      value="other"
                      checked={!!other}
                      onChange={(e) => {
                        if (!other) setForm({ ...form, [step]: "" });
                        setOther(e.target.value);
                      }}
                      required
                    />
                    Other: &nbsp;
                    <input
                      id="question-input"
                      required={!!other}
                      onFocus={() => setOther("other")}
                      maxLength={50}
                      type="text"
                      className="input"
                      value={other ? (form as any)[step] : ""}
                      onChange={(e) =>
                        setForm({ ...form, [step]: e.target.value })
                      }
                    />
                  </label>
                </div>
              )}

              <div className="btn-container">
                {step > 1 && (
                  <button
                    onClick={() => setStep(step - 1)}
                    className="btn action-btn secondary-action-btn"
                    type="button"
                  >
                    Back
                  </button>
                )}
                <button
                  type="submit"
                  className="btn action-btn"
                  disabled={!(form as any)[step]}
                >
                  {step < LAST_QUESTION_KEY ? "Next" : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </CSSTransition>
      </SwitchTransition>
      <div className="progress-bar-container">
        <div
          className="progress-bar"
          style={{ width: `${((step - 1) / LAST_QUESTION_KEY) * 100}%` }}
        ></div>
      </div>
    </div>
  );
};

export default Form;
