import { useRef, FC, ReactNode, Fragment } from "react";
import { CSSTransition } from "react-transition-group";
import Script, { ScriptType } from "./Script";
import "./Script.css";

export interface ScriptSectionProps {
  script: ScriptType[];
  story?: string[];
  showScript: boolean;
  children?: ReactNode;
}

const ScriptSection: FC<ScriptSectionProps> = ({
  script,
  story,
  showScript,
  children,
}) => {
  const nodeRef = useRef(null);

  return (
    <CSSTransition
      in={showScript}
      nodeRef={nodeRef}
      timeout={1000}
      classNames="fade"
      unmountOnExit
    >
      <div>
        <div className="script-section" ref={nodeRef}>
          <div className="container">
            <h2 className="script-heading">Your Company Brand Message</h2>
            <div className="script-grid">
              {script.map((s, i) => (
                <Script key={i} script={s} />
              ))}
            </div>
          </div>
        </div>

        <div className="story-section">
          <div className="container">
            <h2 className="script-heading" style={{ marginTop: "3rem" }}>
              Story
            </h2>
            {story?.length
              ? story.map((s, i) => {
                  if (Array.isArray(s)) {
                    return (
                      <Fragment key={i}>
                        <p>Here&apos;s our simple process:</p>
                        <ol className="steps">
                          {s.map((l, i) => (
                            <li key={i}>{l}</li>
                          ))}
                        </ol>
                      </Fragment>
                    );
                  }

                  return <p key={i}>{s}</p>;
                })
              : null}
          </div>

          {children}
        </div>
      </div>
    </CSSTransition>
  );
};

export default ScriptSection;
