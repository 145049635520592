import { FC } from "react";
import "./Wireframe.css";

export interface WebsiteData {
  cta: string;
  h1: string;
  h2: string;
  h3: string;
  h4: string;
  p1: string;
  p2: string;
  benefitsHeading: string;
  benefits: { heading: string; subheading: string }[];
  problemHeading: string;
  problemParagraphs: string[];
  stepsSummary: string;
  steps: string[];
}

export interface WireframeProps {
  isPreview?: boolean;
  website: WebsiteData;
  stepsHeadings: string[];
}

const Wireframe: FC<WireframeProps> = ({
  isPreview,
  website,
  stepsHeadings,
}) => {
  return (
    <div className={`wireframe ${isPreview ? "preview" : ""}`}>
      <div className="wireframe-heading">
        <div className="wireframe-heading-buttons">
          <div className="wireframe-logo">LOGO</div>

          <div className="wireframe-cta action-btn">{website.cta}</div>
        </div>

        <div className="wireframe-heading-h1">{website.h1}</div>

        <div className="content-normal">{website.h2}</div>

        <div className="wireframe-cta action-btn" style={{ margin: "auto" }}>
          {website.cta}
        </div>
      </div>

      <div className="wireframe-content">
        <div className="content-bold">{website.p1}</div>

        <div className="content-normal">{website.p2}</div>

        <div className="content-heading">{website.benefitsHeading}</div>

        <div className="images-list">
          <div className="image-item">
            <div className="image-spot">Placeholder Icon</div>
            <div className="content-bold">{website.benefits[0].heading}</div>
            <div className="content-normal">
              {website.benefits[0].subheading}
            </div>
          </div>

          <div className="image-item">
            <div className="image-spot">Placeholder Icon</div>
            <div className="content-bold">{website.benefits[1].heading}</div>
            <div className="content-normal">
              {website.benefits[1].subheading}
            </div>
          </div>

          <div className="image-item">
            <div className="image-spot">Placeholder Icon</div>
            <div className="content-bold"> {website.benefits[2].heading}</div>
            <div className="content-normal">
              {website.benefits[2].subheading}
            </div>
          </div>
        </div>

        <div className="content-heading">{website.problemHeading}</div>

        <div className="images-and-texts">
          <div className="image-and-text">
            <div className="image-block">Picture Of Either Logo or Team</div>
            <div className="content-normal">{website.problemParagraphs[0]}</div>
          </div>

          <div className="image-and-text">
            <div className="content-normal">{website.problemParagraphs[1]}</div>
            <div className="image-block">Picture Of Either Logo or Team</div>
          </div>
        </div>

        <div className="content-heading">{website.stepsSummary}</div>
        <div className="content-normal">This is our simple process</div>

        <div className="images-list">
          <div className="image-item">
            <div className="image-spot">Placeholder Icon</div>
            <div className="content-bold">{stepsHeadings[0]}</div>
            <div className="content-normal">{website.steps[0]}</div>
          </div>

          <div className="image-item">
            <div className="image-spot">Placeholder Icon</div>
            <div className="content-bold">{stepsHeadings[1]}</div>
            <div className="content-normal">{website.steps[1]}</div>
          </div>

          <div className="image-item">
            <div className="image-spot">Placeholder Icon</div>
            <div className="content-bold">{stepsHeadings[2]}</div>
            <div className="content-normal">{website.steps[2]}</div>
          </div>
        </div>

        <div className="wireframe-cta action-btn" style={{ margin: "auto" }}>
          {website.cta}
        </div>
      </div>

      <div className="wireframe-footer">
        <div className="content-bold">{website.h3}</div>
        <div className="content-normal">{website.h4}</div>
        <div className="wireframe-cta action-btn" style={{ margin: "auto" }}>
          {website.cta}
        </div>
      </div>

      <div className="wireframe-junk-drawer">
        This is the junk drawer section.
        <br />
        Put your address, a Google map (for SEO), contact information, copyright
        info, and a sitemap.
      </div>
    </div>
  );
};

export default Wireframe;
