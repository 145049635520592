import { ScriptType } from "./Script";

const contentToScript = (script: string[] | string[][]): ScriptType[] => {
  return [
    {
      title: "Your Ideal Customer",
      elements: [
        { content: script[0] as string },
        // { content: script[1] as string },
      ],
    },
    {
      title: "Has a Problem",
      elements: [
        { title: "External Problem", content: script[2] as string },
        { title: "Internal Problem", content: script[3] as string },
        { title: "Philosophical Problem", content: script[4] as string },
      ],
    },
    {
      title: "And Meets The Right Guide",
      elements: [
        { content: script[5] as string },
        { content: script[6] as string },
      ],
    },
    {
      title: "Who Has A Plan",
      elements: [
        {
          content:
            "Customers trust you as a guide because you give them a plan",
          list: script[7] as string[],
        },
      ],
    },
    {
      title: "And Calls Them to Action",
      elements: [{ content: script[8] as string }],
    },
    {
      title: "That Could Result In Failure",
      elements: [{ content: script[9] as string }],
    },
    {
      title: "Or Their Ideal Outcome",
      elements: [{ content: script[10] as string }],
    },
    // {
    //   title: "Here’s Your Villain",
    //   elements: [{ content: script[11] as string }],
    // },
  ];
};

export default contentToScript;
