import "./App.css";
import Form, { SendQuestions } from "./Form";
import Loader from "./Loader";
import ContactForm from "./ContactForm";
import ScriptSection from "./ScriptSection";
import Wireframe from "./Wireframe";
import Footer from "./Footer";
import Header from "./Header";
import VideoDialog from "./VideoDialog";
import { useState } from "react";
import { ScriptType } from "./Script";
import contentToScript from "./content-to-script";

function App() {
  const [needsToSubmitQuestions, setNeedsToSubmitQuestions] = useState(true);
  const [closeDialog, setCloseDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState<{
    pitch?: string;
    headline?: string;
    story?: string[];
  }>({});
  const [script, setScript] = useState<ScriptType[]>([]);
  const [showScript, setShowScript] = useState(false);

  const [questions, setQuestions] = useState<string[]>([]);
  const [originalResponse, setOriginalResponse] = useState("");

  const [contactInfo, setContactInfo] = useState({ firstName: "", email: "" });
  const hasSubmittedContactInfo = contactInfo.firstName && contactInfo.email;

  const sendQuestions: SendQuestions = async (q) => {
    setNeedsToSubmitQuestions(false);
    setLoading(true);

    try {
      setQuestions(q);

      const res = await fetch(
        "https://us-west1-brandscript-generator-390322.cloudfunctions.net/brandscript-generator",
        {
          method: "POST",
          body: JSON.stringify({
            questions: q,
          }),
        }
      );

      if (res.status !== 200) {
        alert("Sorry something went wrong. Please try again later.");
        return;
      }

      const json = await res.json();
      setOriginalResponse(json.choices[0].message.content);

      const content = JSON.parse(json.choices[0].message.content);

      setContent(content);

      setScript(contentToScript(content.script));

      setShowScript(true);
    } catch (e) {
      setNeedsToSubmitQuestions(true);
      alert("Sorry something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const unlock = async () => {
    setLoading(true);

    const params = new URLSearchParams(window.location.search);
    const secret = params.get("secret");

    try {
      const res = await fetch(
        `https://us-west1-brandscript-generator-390322.cloudfunctions.net/brandscript-stripe${
          secret ? `?secret=${secret}` : ""
        }`,
        {
          method: "POST",
          body: JSON.stringify({ originalResponse, questions }),
        }
      );

      if (res.status !== 200) {
        alert("Sorry something went wrong. Please try again later.");
        return;
      }

      const json = await res.json();

      window.location.href = json.url;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header hideGetStarted={!!questions.length} />
      <main id="main">
        {needsToSubmitQuestions && <Form sendQuestions={sendQuestions} />}
        {!needsToSubmitQuestions && !hasSubmittedContactInfo && (
          <ContactForm
            handleSubmit={async (payload) => {
              setContactInfo(payload);

              await fetch(
                "https://hooks.zapier.com/hooks/catch/6228014/31w3e9h/",
                {
                  method: "POST",
                  body: JSON.stringify(payload),
                }
              );
            }}
          />
        )}
        {!needsToSubmitQuestions && !closeDialog && (
          <VideoDialog
            title="Watch This Video To Learn More About The Power Of BrandScripts"
            src="https://player.vimeo.com/video/853168360?h=40430cadc1&autoplay=1&title=0&byline=0&portrait=0"
            setCloseDialog={setCloseDialog}
          />
        )}
        {loading && <Loader />}

        {hasSubmittedContactInfo && (
          <ScriptSection
            script={script}
            story={content.story}
            showScript={showScript}
          >
            {!loading && (
              <div className="upsell container">
                <h2 className="marketing-heading text-center">
                  Watch Your BrandScript Come To Life For Just $29.
                </h2>
                <button
                  className="btn buy-btn action-btn secondary-action-btn"
                  type="button"
                  onClick={unlock}
                >
                  Unlock
                </button>

                <div className="upsell-benefits">
                  <details>
                    <summary className="marketing-heading">Your Pitch</summary>
                    <div>
                      <p className="script-content">{content.pitch}</p>
                      <p className="unlock-message">Unlock for more details</p>
                    </div>
                  </details>

                  <details>
                    <summary className="marketing-heading">
                      Headline Ideas
                    </summary>
                    <div>
                      <ul className="script-content">
                        <li>...</li>
                        <li>...</li>
                        <li>...</li>
                        <li>...</li>
                        <li>...</li>
                      </ul>
                      <p className="unlock-message">Unlock for more details</p>
                    </div>
                  </details>

                  <h2 className="marketing-heading">
                    Here&apos;re A Few Ideas For Your Website
                  </h2>
                  <Wireframe
                    isPreview
                    website={{
                      cta: "Call To Action",
                      h1: "H1 - Use One Of The Proposed Headlines",
                      h2: "H2 - Write a one sentence subheadline describing what the company does",
                      h3: "H3 - Write one sentence about the consequences of not taking call to action",
                      h4: "H4 - Write a sentence about the ideal outcome in a happy customer scenario",
                      p1: "P - Write one sentence joining the external and internal problems",
                      p2: "P - Write a sentence describing how the company is a solution to the problem",
                      benefitsHeading:
                        "Headline To Introduce the Benefits To The Customer",
                      benefits: [
                        {
                          heading: "H3 - First Title Of Benefit #1",
                          subheading: "P - Subheadline description of benefit",
                        },
                        {
                          heading: "H3 - First Title Of Benefit #2",
                          subheading: "P - Subheadline description of benefit",
                        },
                        {
                          heading: "H3 - First Title Of Benefit #3",
                          subheading: "P - Subheadline description of benefit",
                        },
                      ],
                      problemHeading:
                        "H2 - Write a headline describing the below explanatory paragraph ",
                      problemParagraphs: [
                        "P - Write two paragraphs of copy for the website that focusses on how the company solves a problem and delivers consistent results.",
                        "P - Write two paragraphs of copy for the website that focusses on how the company solves a problem and delivers consistent results.",
                      ],
                      stepsSummary:
                        "Write A Headline For The Three Steps To Work With You",
                      steps: [
                        "Subheadline Explanation of Call To Action",
                        "Subheadline Explanation of step two of the business process",
                        "Subheadline Explanation of step 3. Focus on customer experiencing the outcome",
                      ],
                    }}
                    stepsHeadings={[
                      "H3- Call To Action",
                      "H3 - Second Step In Process",
                      "H3 - Second Step In ProcessH3 - Third Step Headline",
                    ]}
                  />
                </div>
              </div>
            )}
          </ScriptSection>
        )}
        <Footer />
      </main>
    </>
  );
}

export default App;
