import { FC } from "react";
import "./Script.css";

export interface ScriptType {
  title: string;
  elements: { title?: string; content: string; list?: string[] }[];
}

export interface ScriptProps {
  script: ScriptType;
}

const Script: FC<ScriptProps> = ({ script }) => (
  <div className="script-item">
    <summary className="script-item-heading">{script.title}</summary>
    <div className="script-content-section">
      {script.elements.map((el, i) => (
        <div key={i}>
          {el.title && <h4 className="grid-content-heading">{el.title}</h4>}
          <p className="grid-content">{el.content}</p>
          {el.list?.length ? (
            <ol>
              {el.list.map?.((l, i) => (
                <li key={i}>{l}</li>
              ))}
            </ol>
          ) : null}
        </div>
      ))}
    </div>
  </div>
);

export default Script;
