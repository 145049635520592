import { FC } from "react";
import { ReactComponent as XIcon } from "./x.svg";
import "./VideoDialog.css";

export interface VideoDialogProps {
  setCloseDialog: (close: boolean) => void;
  title: string;
  src: string;
}

const VideoDialog: FC<VideoDialogProps> = ({ setCloseDialog, title, src }) => (
  <>
    <div id="backdrop"></div>
    <dialog id="dialog" open>
      <form id="dialog-close" method="dialog">
        <button onClick={() => setCloseDialog(true)}>
          <XIcon width="1rem" />
        </button>
      </form>
      <h3 className="dialog-heading">{title}</h3>
      <iframe
        className="video-dialog-iframe"
        title={title}
        src={src}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
    </dialog>
  </>
);

export default VideoDialog;
