import { FC, useEffect } from "react";
import GetClearLogo from "./get-clear-logo-white.png";
import "./Header.css";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

export interface HeaderProps {
  hideGetStarted: boolean;
}

const Header: FC<HeaderProps> = ({ hideGetStarted }) => {
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(75, 0.8571428571, 0.1, 1000);

  const renderer = new THREE.WebGLRenderer();
  renderer.setClearColor(0xffffff, 0);

  const clock = new THREE.Clock();

  let mixer: THREE.AnimationMixer;

  const loader = new GLTFLoader();
  loader.load(
    "/robot_playground.glb",
    function (gltf) {
      mixer = new THREE.AnimationMixer(gltf.scene);
      var action = mixer.clipAction(gltf.animations[0]);
      action.play();
      scene.add(gltf.scene);
    },
    undefined,
    function (error) {
      console.error(error);
    }
  );

  camera.position.z = 4;
  camera.position.y = 0.75;

  function animate() {
    requestAnimationFrame(animate);
    const delta = clock.getDelta();
    if (mixer) mixer.update(delta);
    renderer.render(scene, camera);
  }
  animate();

  useEffect(() => {
    const container = document.getElementById("three-d-canvas-container");
    if (container) {
      container.appendChild(renderer.domElement);
      const dim = container.getBoundingClientRect();
      renderer.setSize(dim.width, dim.height);
    }
  }, []);

  function focus() {
    const inputs = document.getElementsByTagName("input");
    if (inputs.length > 0) {
      // Let scrolling finish
      setTimeout(() => {
        inputs[0].focus();
      }, 500);
    }
  }

  return (
    <div className="header-bg">
      <header className="header-container">
        <a
          className="logo-link"
          href="https://getclear.ca"
          target="_blank"
          rel="noreferrer"
        >
          <img className="logo" src={GetClearLogo} alt="Get Clear" />
        </a>

        <div className="header-title-container">
          <h1 className="header-title">
            Discover The Power Of AI To
            <br />
            <span className="header-title-accent">
              StoryBrand Your Business
            </span>
          </h1>
          <h2 className="header-body">
            Get a free, customized BrandScript done for you in less than a
            minute.
          </h2>

          {hideGetStarted ? null : (
            <a
              href="#main"
              className="btn action-btn get-started"
              onClick={focus}
            >
              Get Started
            </a>
          )}
        </div>

        <div id="three-d-canvas-container"></div>
      </header>
    </div>
  );
};

export default Header;
